import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule } from '@ngx-formly/core';
import { provideClient } from '@sites/data-connect';
import { FilesService } from '@sites/data-hmm/hmm-files';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { ColorComponent } from './color/color.component';
import { DateComponent } from './date/date.component';
import { FileComponent } from './file/file.component';
import { FilesUtilService } from './files.service';
import { InputComponent } from './input/input.component';
import { ModelComponent } from './model/model.component';
import { RepeatComponent } from './repeat/repeat.component';
import { SelectComponent } from './select/select.component';
import { TextComponent } from './text/text.component';
import { TextareaComponent } from './textarea/textarea.component';
import { TimeComponent } from './time/time.component';
import { WrapperComponent } from './wrapper/wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FormlyModule.forRoot({
      types: [
        { name: 'color', component: ColorComponent },
        { name: 'text', component: TextComponent },
        { name: 'checkbox', component: CheckboxComponent },
        { name: 'select', component: SelectComponent },
        { name: 'file', component: FileComponent },
        { name: 'input', component: InputComponent },
        { name: 'textarea', component: TextareaComponent },
        { name: 'repeat', component: RepeatComponent },
        { name: 'model', component: ModelComponent },
        { name: 'date', component: DateComponent },
        { name: 'time', component: TimeComponent },
      ],
      wrappers: [{ name: 'wrapper', component: WrapperComponent }],
    }),
  ],
  declarations: [
    ColorComponent,
    WrapperComponent,
    TextComponent,
    CheckboxComponent,
    SelectComponent,
    FileComponent,
    InputComponent,
    TextareaComponent,
    RepeatComponent,
    ModelComponent,
    DateComponent,
    TimeComponent,
  ],
  exports: [FormlyModule],
  providers: [
    {
      provide: FilesUtilService,
      useClass: FilesUtilService,
    },
    provideClient(FilesService),
  ],
})
export class UiFormlyModule {}
