import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { create } from '@bufbuild/protobuf';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { ObservableClient } from '@sites/data-connect';
import {
  FilesService,
  GetUploadUrlResponse,
  ProcessRequest,
  ProcessRequestSchema,
  ProcessResponse,
  ProcessingOption_AudioSchema,
  ProcessingOption_FileSchema,
  ProcessingOption_ImageSchema,
  ProcessingOption_Type,
  ProcessingOption_VideoSchema,
  ProcessingOption_ZippedSiteSchema,
} from '@sites/data-hmm/hmm-files';
import { Observable, of, throwError } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { FilesUtilService } from '../files.service';

enum StateEnum {
  NoFileUploaded,
  FileUploaded,
  FileUploading,
  Error,
}

enum LoadingEnum {
  Selecting,
  Preparing,
  Uploading,
  Processing,
}

export type ImageFile = {
  key: string;
  thumbnailKey: string;
  widthPx: number;
  heightPx: number;
};

export type VideoFile = {
  key: string;
  thumbnailKey: string;
  posterKey: string;
  gifKey: string;
  widthPx: number;
  heightPx: number;
  durationMs: number;
};

export type AudioFile = {
  key: string;
  durationMs: number;
};

export type FileFile = {
  key: string;
};

export type FileType = AudioFile | FileFile | ImageFile | VideoFile;

export function isFile(media?: unknown): media is FileType {
  return Object.prototype.hasOwnProperty.call(media, 'key');
}

export function isImageFile(media?: FileType): media is ImageFile {
  return checkProperties(true, false, media);
}

export function isVideoFile(media?: FileType): media is VideoFile {
  return checkProperties(true, true, media);
}

export function isAudioFile(media?: FileType): media is AudioFile {
  return checkProperties(false, true, media);
}

export function isFileFile(media?: FileType): media is FileFile {
  return checkProperties(false, false, media);
}

function checkProperties(
  thumbnail: boolean,
  duration: boolean,
  media?: FileType
): boolean {
  return (
    (media &&
      Object.prototype.hasOwnProperty.call(media, 'thumbnailKey') ===
        thumbnail &&
      Object.prototype.hasOwnProperty.call(media, 'durationMs') === duration) ||
    false
  );
}

export interface FileFieldTypeConfig<T = FormlyFieldConfig['props']>
  extends FormlyFieldConfig<T> {
  formControl: FormControl<FileType | undefined>;
  props: NonNullable<T>;
}

@Component({
  selector: 'property-form-file',
  templateUrl: './file.component.html',
})
export class FileComponent
  extends FieldType<FileFieldTypeConfig>
  implements OnInit
{
  error?: string;
  accept = '';

  state?: StateEnum;
  stateEnum = StateEnum;

  loadingState?: LoadingEnum;
  loadingEnum = LoadingEnum;

  progressNumber = {
    [LoadingEnum.Selecting]: 5,
    [LoadingEnum.Preparing]: 15,
    [LoadingEnum.Uploading]: 40,
    [LoadingEnum.Processing]: 75,
  };

  viewableLink = '';

  constructor(
    @Inject(FilesService)
    private fileService: ObservableClient<typeof FilesService>,
    private filesUtilService: FilesUtilService,
    private http: HttpClient
  ) {
    super();
  }

  ngOnInit() {
    this.setAccept();
    this.state = this.formControl.value?.key
      ? StateEnum.FileUploaded
      : StateEnum.NoFileUploaded;
    if (this.state == StateEnum.FileUploaded) {
      this.setViewableLink(this.formControl.value);
    }
  }

  onChange(event: Event) {
    this.formControl.setErrors({ uploading: true });

    this.state = StateEnum.FileUploading;
    this.loadingState = LoadingEnum.Selecting;

    this.getFileFromEvent(event)
      .pipe(
        tap(() => (this.loadingState = LoadingEnum.Preparing)),
        switchMap((file) => this.getUploadUrl(file)),
        tap(() => (this.loadingState = LoadingEnum.Uploading)),
        switchMap(({ upload, file }) => this.uploadFile(upload, file)),
        tap(() => (this.loadingState = LoadingEnum.Processing)),
        switchMap((upload) => this.processFile(upload.key || ''))
      )
      .subscribe({
        next: (res) => {
          this.setValue(res);
        },
        error: (err) => {
          this.setError(err);
        },
      });
  }

  removeFile() {
    this.setValue();
  }

  gotoViewableLink() {
    if (this.viewableLink === '') {
      return;
    }
    window.open(this.viewableLink);
  }

  private getFileFromEvent(event: Event): Observable<File> {
    const target = event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      return of(target.files[0]);
    }
    return throwError('No file selected');
  }

  private getUploadUrl(
    file: File
  ): Observable<{ upload: GetUploadUrlResponse; file: File }> {
    return this.fileService
      .getUploadUrl({
        filename: file.name,
        contentType: file.type,
      })
      .pipe(map((res) => ({ upload: res, file })));
  }

  private uploadFile(
    upload: GetUploadUrlResponse,
    file: File
  ): Observable<GetUploadUrlResponse> {
    if (!upload.url) {
      throw new Error('No signed upload URL');
    }

    return this.http.put(upload.url, file).pipe(map(() => upload));
  }

  private processFile(key: string): Observable<ProcessResponse> {
    return this.fileService.process(this.generateProcessRequest(key));
  }

  private generateProcessRequest(key: string): ProcessRequest {
    const request = create(ProcessRequestSchema, {
      type: ProcessingOption_Type.CREATIVE,
      sourceKey: key,
    });

    switch (this.props.type) {
      case 'image':
        request.option = {
          case: 'image',
          value: create(ProcessingOption_ImageSchema),
        };
        break;
      case 'video':
        request.option = {
          case: 'video',
          value: create(ProcessingOption_VideoSchema),
        };
        break;
      case 'file':
        request.option = {
          case: 'file',
          value: create(ProcessingOption_FileSchema),
        };
        break;
      case 'audio':
        request.option = {
          case: 'audio',
          value: create(ProcessingOption_AudioSchema),
        };
        break;
      case 'zip':
        request.option = {
          case: 'zippedSite',
          value: create(ProcessingOption_ZippedSiteSchema),
        };
        break;
      default:
        throw new Error(`Type "${this.props.type}" not supported`);
    }

    return request;
  }

  private setAccept() {
    switch (this.props.type) {
      case 'image':
        this.accept = 'image/*';
        break;
      case 'video':
        this.accept = 'video/*';
        break;
      case 'file':
        this.accept = 'application/*';
        break;
      case 'audio':
        this.accept = 'audio/*';
        break;
      case 'zip':
        this.accept = 'application/zip';
        break;
      default:
        throw new Error(`Type "${this.props.type}" not supported`);
    }
  }

  private setValue(response?: ProcessResponse) {
    if (!response) {
      this.formControl.setValue(undefined);
      this.viewableLink = '';
      this.state = StateEnum.NoFileUploaded;
      return;
    }

    const value = this.mapValue(response.result);
    this.formControl.setValue(value);
    this.setViewableLink(value);

    this.state = StateEnum.FileUploaded;
  }

  private mapValue(result: ProcessResponse['result']): FileType {
    switch (result.case) {
      case 'image':
        return {
          key: result.value?.key,
          thumbnailKey: result.value?.thumbnailKey,
          widthPx: result.value?.widthPx,
          heightPx: result.value?.heightPx,
        };
      case 'video':
        return {
          key: result.value?.key,
          thumbnailKey: result.value?.thumbnailKey,
          posterKey: result.value?.posterKey,
          gifKey: result.value?.gifKey,
          widthPx: result.value?.widthPx,
          heightPx: result.value?.heightPx,
          durationMs: result.value?.durationMs,
        };
      case 'audio':
        return {
          key: result.value?.key,
          durationMs: result.value?.durationMs,
        };
      case 'file':
      case 'zippedSite':
        return {
          key: result.value?.key,
        };
      default:
        throw new Error(`Type "${this.props.type}" not supported`);
    }
  }

  private setViewableLink(value?: FileType) {
    if (!value) {
      return;
    }
    this.filesUtilService.generateLink(value.key).subscribe((l) => {
      this.viewableLink = l;
    });
  }

  private setError(error: Error) {
    this.state = StateEnum.Error;
    if (error instanceof HttpErrorResponse) {
      this.error = 'Error uploading file';
    } else {
      this.error = error.message;
    }
  }
}
