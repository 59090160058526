import { Component } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'property-form-color',
  templateUrl: './color.component.html',
})
export class ColorComponent extends FieldType<FieldTypeConfig> {
  onChange(event: Event) {
    if (event.target instanceof HTMLInputElement) {
      this.formControl.setValue(event.target.value);
    }
  }
}
