import { Inject, Injectable } from '@angular/core';
import { Code, ConnectError } from '@connectrpc/connect';
import { ObservableClient } from '@sites/data-connect';
import { FilesService, GetPublicUrlResponse } from '@sites/data-hmm/hmm-files';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class FilesUtilService {
  constructor(
    @Inject(FilesService)
    private filesService: ObservableClient<typeof FilesService>
  ) {}

  generateLink(link: string, signal?: AbortSignal): Observable<string> {
    if (
      link.startsWith('http') ||
      link === '' ||
      (!link.startsWith('creatives/') && !link.startsWith('client_site'))
    ) {
      return of(link);
    }
    return this.filesService
      .getPublicUrl(
        {
          key: link,
        },
        {
          signal,
        }
      )
      .pipe(
        map((res: GetPublicUrlResponse) => {
          return res.url || link;
        }),
        catchError((err) => {
          // Ignore canceled errors, the signal was aborted as the link was no longer needed
          if (err instanceof ConnectError && err.code === Code.Canceled) {
            return of(link);
          }
          throw err;
        })
      );
  }
}
