<textarea
  [formControl]="formControl"
  class="form-control"
  [formlyAttributes]="field"
></textarea>
<small>
  {{ formControl.value.length }}
  @if (props.description) {
    -
  }
</small>
