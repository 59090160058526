<div class="tw-mb-3 tw-flex tw-flex-col tw-gap-3">
  @for (field of field.fieldGroup; track $index; let i = $index) {
    <div class="tw-border tw-border-night-4 tw-p-4">
      <formly-field [field]="field"></formly-field>
      <div class="tw-text-end">
        <button
          class="tw-btn tw-btn-sm tw-btn-danger"
          type="button"
          (click)="remove(i)"
        >
          {{ to['removeLabel'] }}
        </button>
      </div>
    </div>
  }

  <div class="tw-border tw-border-night-4 tw-p-4 tw-text-end">
    <button
      class="tw-btn tw-btn-sm tw-btn-primary"
      type="button"
      (click)="add(undefined, initialModel())"
    >
      {{ to['addLabel'] }}
    </button>
  </div>
</div>
