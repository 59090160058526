<div class="input-group">
  @if (props['prepend']) {
    <span class="input-group-text">{{ props['prepend'] }}</span>
  }
  <input
    [placeholder]="props['placeholder']"
    [type]="type"
    [formControl]="formControl"
    class="form-control"
    [formlyAttributes]="field"
  />
  @if (props['append']) {
    <span class="input-group-text">{{ props['append'] }}</span>
  }
</div>
