import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';
import { isObservable } from 'rxjs';

@Component({
  selector: 'property-form-select',
  templateUrl: './select.component.html',
})
export class SelectComponent
  extends FieldType<FieldTypeConfig>
  implements OnInit
{
  items: unknown[] = [];
  clearable = false;

  ngOnInit() {
    if (isObservable(this.props.options)) {
      this.props.options
        .subscribe((options) => {
          this.items = options;
        })
        .unsubscribe();
    } else {
      this.items = this.props.options || [];
    }
  }
}
