<div class="input-group">
  <div
    class="input-group-text position-relative justify-content-center p-0"
    [ngStyle]="{ 'background-color': formControl.value, width: '40px' }"
  >
    <i class="bi bi-eyedropper"></i>
    <input
      type="color"
      class="w-100 h-100 position-absolute cursor-pointer opacity-0"
      [formControl]="formControl"
    />
  </div>
  <input
    type="text"
    [value]="formControl.value"
    (change)="onChange($event)"
    class="form-control"
    [formlyAttributes]="field"
  />
</div>
